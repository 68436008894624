import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../components/seo';
import Hero from '../components/hero';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import HomeSelector from '../components/home-selector';
export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "exteriors/BUNGALOW Modern connected elev.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Your Addition" mdxType="SEO" />
    <Hero heroImage={props.data.heroImage} mdxType="Hero">
  <h1 className="text-3xl">Your Addition</h1>
  <p className="text-xl">Transportable anywhere, environmentally conscious, and ready to increase the value and flexibility of any home in just a few days.</p>
    </Hero>
    <p>{`A light-filled home office, extra bedroom, games den, or rental space--we have a range of ideal build options for those seeking an affordable external addition to their home. Available with or without kitchens, bathrooms and separate bedrooms, and transportable on a standard-width trailer, Fluid Solar builds are ready to improve your property with a low impact on your land and on the earth.`}</p>
    <p>{`We can deliver and assemble your project in around `}<strong parentName="p">{`48 hours`}</strong>{` on a prepared site. Passive design elements to improve energy consumption and temperature control are combined with our patented energy collection technology to cut typical energy costs for occupants by as much as 90%, and can be made to function with standard grid connection, reduced grid dependency, or entirely free from the grid. Explore our options and styles below, and discover how you can make the most of your property.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0eb36331b544bad8aaf382ca127912de/b89a9/Gable_1_Standard_closeup_verandah.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABYxYVzUieD//EAB0QAAMAAAcAAAAAAAAAAAAAAAABAgMEERITITP/2gAIAQEAAQUCzPnuk5JRj9ypRoj/xAAXEQADAQAAAAAAAAAAAAAAAAAAARES/9oACAEDAQE/AUqZP//EABcRAAMBAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8Bbho//8QAGRAAAwADAAAAAAAAAAAAAAAAAAExEHGB/9oACAEBAAY/AuilKLZMf//EABsQAAICAwEAAAAAAAAAAAAAAAABETEhQWFx/9oACAEBAAE/Ia/A+xCeEBLLGhIDoI6Wcj//2gAMAwEAAgADAAAAENzv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxAT/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxAT/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAITFBUXHw/9oACAEBAAE/EPQ5hoIByCSbvcQQKF4wF/KwadQJXYDgI2OVP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gable 1 Standard closeup verandah",
            "title": "Gable 1 Standard closeup verandah",
            "src": "/static/0eb36331b544bad8aaf382ca127912de/b89a9/Gable_1_Standard_closeup_verandah.jpg",
            "srcSet": ["/static/0eb36331b544bad8aaf382ca127912de/c635c/Gable_1_Standard_closeup_verandah.jpg 500w", "/static/0eb36331b544bad8aaf382ca127912de/7e3f6/Gable_1_Standard_closeup_verandah.jpg 1000w", "/static/0eb36331b544bad8aaf382ca127912de/b89a9/Gable_1_Standard_closeup_verandah.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div className="escape-container">
  <HomeSelector category="categoryAddition" mdxType="HomeSelector" />
    </div>
    {
      /*
      TODO: I’m looking for…
      Decision tree, including
      *   The Den
      *   Bungalow
      *   Refuge 2 and 3?
      *   Needs to be confirmed and discussed with RD.
      */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      